// src/History.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./History.css";

function History() {
  const [history, setHistory] = useState([]);
  // Set initial currentIndex based on history length
  const [currentIndex, setCurrentIndex] = useState(() => history.length - 1);

  useEffect(() => {
    const fetchHistory = async () => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId) {
        try {
          const response = await axios.get(`/api/history/${uniqueId}`);
          const fetchedHistory = response.data.reverse(); // Reverse to start with the most recent
          setHistory(fetchedHistory);
          setCurrentIndex(fetchedHistory.length - 1); // Initialize index to the last item or -1 if empty
        } catch (error) {
          console.error("Error fetching history:", error);
        }
      }
    };

    fetchHistory();
  }, []);

  // Helper functions to determine button state
  const isAtStart = () => currentIndex <= 0;
  const isAtEnd = () =>
    currentIndex >= history.length - 1 || history.length === 0;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, history.length - 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleCopyHistoryItem = (item) => {
    if (!item) return; // Early return if item is undefined

    const textToCopy = `Prompt: ${item.prompt}\n\n${item.poem}`;

    // Attempt to use an intermediate textarea to ensure plain text copying
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      // alert("Poem copied to clipboard!");
    } catch (err) {
      console.error("Could not copy poem: ", err);
    }
    document.body.removeChild(textArea);
  };

  const handleDeleteItem = async (item) => {
    if (!item) return; // Early return if item is undefined

    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`/api/history/item/${item.id}`);
        const updatedHistory = history.filter(
          (historyItem) => historyItem.id !== item.id
        );
        setHistory(updatedHistory);

        // Adjust currentIndex to remain within the new array bounds
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      } catch (error) {
        console.error("Error deleting history item:", error);
      }
    }
  };

  const handleDeleteAll = async () => {
    const uniqueId = localStorage.getItem("uniqueId");
    if (
      uniqueId &&
      history.length > 0 &&
      window.confirm("Are you sure you want to delete all your history?")
    ) {
      try {
        await axios.delete(`/api/history/all/${uniqueId}`);
        setHistory([]);
        setCurrentIndex(0); // Reset index
      } catch (error) {
        console.error("Error deleting all history items:", error);
      }
    }
  };

  return (
    <div className="historyPage">
      <div className="background">
        <div className="container">
          <div className="content">
            <div className="panel">
              {history.length > 0 ? (
                <>
                  <div className="historyItem">
                    <div>
                      <h3>Prompt</h3>
                      {history[currentIndex].prompt}
                    </div>
                    <div>
                      <h3>Response</h3>
                      {history[currentIndex].response}
                    </div>
                  </div>
                </>
              ) : (
                <div className="no-blogs">
                  <p>
                    Go write a blog! It will collect here in your journal. You
                    can always delete it later if you want.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="navigation">
          <div className="icons">
            <i
              className={`fa-solid fa-backward ${
                isAtStart() || history.length === 0 ? "disabled" : ""
              }`}
              title="Back"
              onClick={handlePrev}
            ></i>
            <i
              className="fa-solid fa-copy"
              title="Copy"
              onClick={() => handleCopyHistoryItem(history[currentIndex])}
            ></i>
            <i
              className="fa-solid fa-broom-wide"
              title="Remove This Poem"
              onClick={() => handleDeleteItem(history[currentIndex])}
            ></i>
            <i
              className="fa-solid fa-trash-xmark"
              title="Forget All"
              onClick={handleDeleteAll}
            ></i>
            <i
              className={`fa-solid fa-forward ${
                isAtEnd() || history.length === 0 ? "disabled" : ""
              }`}
              title="Next"
              onClick={handleNext}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
