// src/GptForm.js
import React, { useState, useEffect } from "react";
import axios from "axios";

function GptForm({ assistantType, uniqueId }) {
  // Retrieve or initialize the prompt and responseText from sessionStorage
  const [prompt, setPrompt] = useState(() => {
    const savedPrompt = sessionStorage.getItem(`${assistantType}-prompt`);
    return savedPrompt || "";
  });
  const [responseText, setResponseText] = useState(() => {
    const savedResponse = sessionStorage.getItem(`${assistantType}-response`);
    return savedResponse || "";
  });

  const [isLoading, setIsLoading] = useState(false); // Track loading state

  useEffect(() => {
    // Update prompt and responseText based on the current assistantType
    const savedPrompt = sessionStorage.getItem(`${assistantType}-prompt`);
    const savedResponse = sessionStorage.getItem(`${assistantType}-response`);
    setPrompt(savedPrompt || "");
    setResponseText(savedResponse || "");
  }, [assistantType]); // Dependency array ensures this effect runs when assistantType changes

  const handleTextareaChange = (e) => {
    setPrompt(e.target.value);
    sessionStorage.setItem(`${assistantType}-prompt`, e.target.value); // Save prompt on change
  };

  // Modify the handleSubmit function to use assistantType-specific threadId

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check the honeypot field
    const honeypotValue = e.target.email.value;
    if (honeypotValue) {
      console.log("Bot submission detected, aborting...");
      return; // Exit the function to prevent form submission
    }

    setIsLoading(true);

    // Use an assistantType-specific key to store and retrieve the threadId
    const threadIdKey = `${assistantType}-threadId`;
    const threadId = sessionStorage.getItem(threadIdKey); // Adjusted to use specific key

    try {
      const response = await axios.post("/api/request", {
        prompt,
        threadId, // This threadId is now specific to the assistantType
        assistantType,
        uniqueId,
      });

      const newResponseText = response.data.responseText || "No text found";
      setResponseText(newResponseText);

      // Save the response and threadId back to sessionStorage using assistantType-specific keys
      sessionStorage.setItem(`${assistantType}-response`, newResponseText);

      // Only update the threadId in sessionStorage if a new one is provided by the response
      if (response.data.threadId) {
        sessionStorage.setItem(threadIdKey, response.data.threadId);
      }
    } catch (error) {
      console.error("Error fetching response:", error);
      setResponseText("Sorry, there was an error.\n" + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    const textToCopy = `${responseText}`;

    // Attempt to use an intermediate textarea to ensure plain text copying
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Could not copy text: ", err);
    }
    document.body.removeChild(textArea);
  };
  const handleClear = () => {
    setPrompt("");
    setResponseText("");
    sessionStorage.removeItem(`${assistantType}-prompt`);
    sessionStorage.removeItem(`${assistantType}-response`);
  };
  const handleForget = () => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to forget all the blog copy generated here and start fresh? I won't have any knowledge of what we've discussed so far."
    );

    // Only proceed if the user confirmed the action
    if (isConfirmed) {
      setPrompt("");
      setResponseText("");
      sessionStorage.removeItem(`${assistantType}-prompt`);
      sessionStorage.removeItem(`${assistantType}-response`);
      sessionStorage.removeItem(`${assistantType}-threadId`); // Ensure this is assistantType-specific

      // Optionally, clear all session storage if you really want everything forgotten
      // Be cautious, as this will remove everything stored in the session storage for your origin
      // sessionStorage.clear();
    }
  };

  return (
    <div className={assistantType.toLowerCase()}>
      <div className="background">
        <div className="container">
          <div className="panel">
            <form className="prompt" onSubmit={handleSubmit}>
              <textarea
                value={prompt}
                // onClick={handleTextareaClick}
                onChange={handleTextareaChange}
                className={prompt ? "has-content" : "empty"}
                minLength="1"
                maxLength="2000"
                placeholder={"Prompt me..."}
                rows="8"
              ></textarea>

              <input
                type="text"
                name="email"
                className="email"
                tabIndex="-1"
                autoComplete="off"
              />

              <button type="submit" disabled={isLoading}>
                {isLoading ? "Composing..." : "Go"}
              </button>
            </form>
            <div
              className={`responseText ${
                responseText && !isLoading ? "fade-in" : ""
              }`}
            >
              {responseText}
            </div>
            <div className="icon-bar">
              <i
                className="fa-solid fa-copy"
                title="Copy"
                onClick={handleCopy}
              ></i>
              <i
                className="fa-solid fa-broom-wide"
                title="Clear Page"
                onClick={handleClear}
              ></i>
              <i
                className="fa-solid fa-trash-xmark"
                title="Forget All"
                onClick={handleForget}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GptForm;
