// /src/HomePage.js
import React from "react";
import "./HomePage.css";

function HomePage() {
  return (
    <div className="homePage">
      <div className="background">
        <div className="container">
          <div className="content">
            <h1>If You're Not Laura, Go Away</h1>
            <p>
              This a Kineto Creative proprietary tool. If you're not with Kineto
              Creative, you should leave. Someone could be hurt, and it would be
              a terrible shame if something bad happened just because someone
              got a little too curious.
            </p>
            {/* Add more content here as needed */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
